import React from "react";
import Layout from "@/components/layout";
import Footer from "@/components/footer";
// import GoogleMap from "@/components/google-map";
// import ClientCarousel from "@/components/client-carousel";
import AboutTwo from "@/components/about-two";
// import BlogTwo from "@/components/blog-two";
import ContactOne from "@/components/contact-one";
// import PricingOne from "@/components/pricing-one";
// import TestimonialsOne from "@/components/testimonials-one";
import FeaturedTwo from "@/components/featured-two";
import SliderTwo from "@/components/slider-two";
import ServiceTabTwo from "@/components/service-tab-2";
import CallToAction from "@/components/call-to-action";
import HeaderOne from "@/components/header-one";
import MenuContextProvider from "@/context/menu-context";

import { graphql } from "gatsby"


const Home = (props) => {
    let data = props.data.wpgraphql;

    console.log(data);

    function constructMetaData(page, currentPage) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://topaper.gr`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://topaper.gr`,
            fbType: "website",
            locale: 'el',
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : page.homePageAcf.introBackground.sourceUrl
        }
        return payload
    }

    return (
        <MenuContextProvider>
            <Layout PageTitle="Home Two" serving={data.categories} metaData={constructMetaData(data.page, props.pageContext.currentPage)} >
                <HeaderOne serving={data.categories} />
                <SliderTwo
                    backImg={data.page.homePageAcf.introBackground}
                    title={data.page.homePageAcf.introHeader1}
                    par={data.page.homePageAcf.introParagraph}
                    introTab={data.page.homePageAcf.introTab}
                />
                <AboutTwo
                    data={data.page.homePageAcf.aboutGroup}
                />
                <FeaturedTwo data={data.page.homePageAcf.featuredGroup} />
                <ServiceTabTwo
                    data={data.categories}
                />
                {/* <TestimonialsOne /> */}
                {/* <PricingOne /> */}
                <CallToAction
                    data={data.page.homePageAcf.generalGroup}
                />
                {/* <BlogTwo /> */}
                {/* <ClientCarousel /> */}
                <ContactOne data={data.page.homePageAcf.contactInformation} />
                {/* <GoogleMap extraClassName="contact-page" /> */}
                <Footer />
            </Layout>
        </MenuContextProvider>
    );
};

export default Home;


export const pageQuery = graphql`query GET_HOME($id: ID!) {
    wpgraphql {
        page(id: $id) {
            seo {
                canonical
                title
                metaDesc
                opengraphDescription
                opengraphTitle
                opengraphImage {
                    sourceUrl
                }
            }
            homePageAcf {
                ...HomeIntroFragment
                ...HomeAboutFragment
                ...HomeFeaturedFragment
                ...GeneralCtaFragment
                contactInformation{
                    title
                    subtitle
                    content
                }
            }
        }

        categories (first:500) {
            edges {
              node {
                name
                description
                slug
                categoryPageH1{
                  categoryImage{
                    altText
                    sourceUrl
                    imageFile {
                        childImageSharp {
                            gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
                        }
                    }
                  }
                }
                children {
                  edges {
                    node {
                      name
                      posts {
                        edges {
                          node {
                            title
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
        }
  
    }
}
`