import React from "react";
import { Link, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"

const CallToAction = (props) => {

    let data = props.data;
    return (
        <section className="cta-style-three">
            <div style={{ display: "grid" }}>
                <GatsbyImage
                    style={{
                        gridArea: "1/1",
                        // You can set a maximum height for the image, if you wish.
                    }}
                    className="py-32 md:py-16"
                    alt={data.image.altText}
                    loading={"lazy"}
                    image={data.image.imageFile.childImageSharp.gatsbyImageData}
                    formats={["auto", "webp", "avif"]}
                />
                <div
                    style={{
                        // By using the same grid area for both, they are stacked on top of each other
                        gridArea: "1/1",
                        position: "relative",
                        // This centers the other elements inside the hero component
                        placeItems: "center",
                        display: "grid",
                    }}
                >
                    <div className="thm-container text-center">
                        <span>{data.subtitle}</span>
                        <h2>{data.title}</h2>
                        <Link to={data.ctaButton.url} className="thm-btn">
                            {data.ctaButton.label}
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CallToAction;

export const query = graphql`fragment GeneralCtaFragment on WPGraphQL_Page_Homepageacf {
    generalGroup {
        title
        subtitle
        image {
          altText
          sourceUrl
          imageFile {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
              }
          }
        }
        ctaButton {
            label
            url
        }
    }
  }
`