import React from "react";
import { graphql } from 'gatsby'

import { Col, Row } from "react-bootstrap";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"


const SliderTwo = (props) => {


    return (
        <section className="banner-style-two">
            <div className="thm-container-fluid clearfix">
                <Row>
                    <Col lg={6} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div className="banner-carousel-two">
                            <div className="item">
                                <div className="single-banner-carousel text-center">
                                    <span>{props.par}</span>
                                    <h1>{props.title}</h1>
                                    <Link to={props.introTab.buttonAction} className="thm-btn yellow-bg">
                                        {props.introTab.buttonText}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div style={{ display: "grid", height:"100%" , backgroundColor: "#b30000", backgroundImage: "linear-gradient( to top right, #b30000, #693d3de8 )"}}>
                            {/* <GatsbyImage
                                alt={props.backImg.altText}
                                loading={"eager"}
                                style={{
                                    gridArea: "1/1",
                                    // You can set a maximum height for the image, if you wish.
                                }}
                                image={props.backImg.imageFile.childImageSharp.gatsbyImageData}
                                formats={["auto", "webp", "avif"]}
                                className="float-left w-full h-full"
                                objectPosition="left center"
                            /> */}
                            <div
                                style={{
                                    // By using the same grid area for both, they are stacked on top of each other
                                    gridArea: "1/1",
                                    position: "relative",
                                    // This centers the other elements inside the hero component
                                    placeItems: "center",
                                    display: "grid",
                                }}
                            >
                                <div className="thm-container text-center py-16" style={{color: "white"}}>
                                    <h2 style={{fontFamily: "Pacifico", fontSize: "40px"}}>Χαρτικά-Απορρυπαντικά</h2>
                                    <h2 style={{fontFamily: "Pacifico", fontSize: "40px"}}>Είδη συσκευασίας-Σακούλες</h2>
                                    <h2 style={{fontFamily: "Pacifico", fontSize: "40px"}}>Εισαγωγές-Εξαγωγές</h2>
                                    <h2 style={{fontFamily: "Pacifico",color: "#fec931", fontSize: "40px"}} className="pt-8">ΤΡΟΦΟΔΟΣΙΕΣ ΚΑΤΑΣΤΗΜΑΤΩΝ ΜΕ ΚΑΘΗΜΕΡΙΝΕΣ ΔΙΑΝΟΜΕΣ ΤΩΝ ΠΡΟΙΟΝΤΩΝ ΜΑΣ</h2>
                                </div>
                            </div>
                        </div>
                        {/* <img src={image} className="float-left" alt="Awesome Image" /> */}
                    </Col>
                </Row>
            </div>
        </section>
    );
};

export default SliderTwo;


export const query = graphql`fragment HomeIntroFragment on WPGraphQL_Page_Homepageacf {
    introBackground {
        sourceUrl
        altText
        imageFile {
            childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
            }
        }
    }

    introHeader1
    introParagraph
    introTab {
        buttonText
        buttonAction
    }
}
`
