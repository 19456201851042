import React from "react";
import { Col, Row } from "react-bootstrap";
// import SectionTitle from "./section-title";
import { Link, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"



const AboutTwo = (props) => {

    let data = props.data;

    return (
        <section className="welcome-section sec-pad">
            <div className="thm-container">
                <Row>
                    <Col lg={6}>
                        <div className="welcome-img-box">
                            <GatsbyImage
                                alt={data.image.altText}
                                loading={"lazy"}
                                image={data.image.imageFile.childImageSharp.gatsbyImageData}
                                formats={["auto", "webp", "avif"]}
                                className="float-left"
                            />
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="welcome-content">
                            <div className="title">
                                <p>{data.subTitle}</p>
                                <h3>{data.title}</h3>
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: data.content }} />
                            <Link to={data.ctaButton.url} className="thm-btn yellow-bg">
                                {data.ctaButton.label}
                            </Link>
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    );
};

export default AboutTwo;

export const query = graphql`fragment HomeAboutFragment on WPGraphQL_Page_Homepageacf {
    aboutGroup {
        title
        subTitle
        content
        ctaButton {
            label
            url
        }

        image {
            sourceUrl
            altText
            imageFile {
                childImageSharp {
                    gatsbyImageData(quality: 100, layout: CONSTRAINED, placeholder: BLURRED, width:570)
                }
            }
        }
    }
  }
  `
