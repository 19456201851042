import React from "react";
import featureHand from "@/images/unique-feature-hand.png";
import { FeatureTwoData } from "@/data";
import { Col, Row } from "react-bootstrap";
import { Link, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"


const FeaturedTwo = (props) => {
    const { sectionContent, posts } = FeatureTwoData;
    const { title, subText, content } = sectionContent;

    let data = props.data;
    return (
        <section className="service-style-two">
            <div className="overlay"></div>
            <div className="thm-container">
                <Row>
                    <Col lg={6}>
                        <div className="service-content">
                            <span>{data.subtitle}</span>
                            <h2>{data.title}</h2>
                            <p>{data.content}</p>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <Row>
                            {data.values.map((item, index) => (
                                <Col md={4} className="text-center" key={index}>
                                    <div className="unique-feature">
                                        <img src={featureHand} alt={"xeri"} />
                                        <div className="content">
                                            <GatsbyImage
                                                alt={item.image.altText}
                                                loading={"lazy"}
                                                image={item.image.imageFile.childImageSharp.gatsbyImageData}
                                                formats={["auto", "webp", "avif"]}
                                                style={{ marginTop: "2.5rem"}}
                                            />
                                            <Link to={"/"}>
                                                <h3>{item.title}</h3>
                                            </Link>
                                        </div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
            </div>
        </section>
    );
};

export default FeaturedTwo;

export const query = graphql`fragment HomeFeaturedFragment on WPGraphQL_Page_Homepageacf {
    featuredGroup{
        title
        subtitle
        content
        values{
            title
            image{
                altText
                sourceUrl
                imageFile {
                    childImageSharp {
                        gatsbyImageData(quality: 100, layout: CONSTRAINED, placeholder: BLURRED, width:150)
                    }
                }
            }
        }
    }
  }
  `