import React, { Fragment, useState, useEffect } from "react";
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby";


const ServiceTabTwo = (props) => {
    const [active, setActive] = useState(-1);
    let data = props.data;

    useEffect(() => {
        let firstRender = true;
        data.edges.map((cat, index) => {
            if (cat.node.children.edges && cat.node.children.edges.length > 0 && firstRender) {
                setActive(index)
                firstRender = false;
            }
        })
    }, [])

    return (
        <Fragment>
            <section className="video-box-design-guide sec-pad service-tab-box">
                <div className="thm-container">
                    <div className="tab-title">
                        <ul className="list-inline">
                            {data.edges.map((cat, index) => {
                                if (cat.node.children.edges && cat.node.children.edges.length > 0) {
                                    return (
                                        <li
                                            key={index}
                                            className={`${active === index ? "active" : " "}`}
                                        >
                                            <a
                                                className="hvr-bounce-to-bottom"
                                                href={`service-tab-${index}`}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setActive(index);
                                                    // document.getElementsByClassName("scHere")[0].scrollIntoView();
                                                }}
                                            >
                                                {/* <GatsbyImage
                                            src={cat}
                                        /> */}
                                                <h3>{cat.node.name}</h3>
                                            </a>
                                        </li>
                                    )
                                }
                            })}
                        </ul>
                    </div>
                    <div className="tab-content scHere">
                        {data.edges.map((cat, index) => {
                            return index === active ? (
                                <div
                                    className="single-tab-content tab-pane show fade in active animated fadeIn"
                                    key={index}
                                >
                                    <div className="sec-title text-center" style={{marginBottom: "24px"}}>
                                        {/* <span>{content.subText}</span> */}
                                        <h3>{cat.node.name}</h3>
                                        <p>{cat.node.description}</p>
                                    </div>
                                    <div className="flex justify-center">
                                        <Link to={`/${cat.node.slug}/`} className="thm-btn yellow-bg mb-8">
                                            Περισσότερα
                                        </Link>
                                    </div>
                                    <div>
                                        <GatsbyImage
                                            alt={cat.node.categoryPageH1.categoryImage.altText}
                                            loading={"lazy"}
                                            image={cat.node.categoryPageH1.categoryImage.imageFile.childImageSharp.gatsbyImageData}
                                            formats={["auto", "webp", "avif"]}
                                        />
                                    </div>
                                </div>
                            ) : null;
                        })}
                    </div>
                </div>
            </section>
        </Fragment>
    );
};

export default ServiceTabTwo;
